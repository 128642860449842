article{
    
    background-color: white;
    background-image:linear-gradient(to bottom,#02abeede,#02abeeda), url('https://media.glassdoor.com/l/fb/c9/43/00/alight-team-at-workday-rising-2017.jpg');
    background-size: cover;
    background-position: center;
    // height: 80vh;


.head-container{
background-image:linear-gradient(to right,#8d6403,#684900,#eed60200), url('https://www.kenyanews.go.ke/wp-content/uploads/2024/02/1709110434508.-NAIROBI-SUMMIT-PHOTO-1-960x630.jpg');
background-size: cover;
background-position: center right;
background-repeat: no-repeat;
filter: drop-shadow(32px);
.head-delay{
    // background-color: red;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: calc(55% - 10px); 
        margin: 5px; 
        height: 30vh;

        .head-image{
            width: 200px;
            height: 200px;
            img{
                width: 100%;
                height: 100%;
                z-index: 0;
            }
        }
        .head-text{
            font-size: 2rem;
            font-family: "Montserrat", Sans-serif;
            // width: 50%;

            h1{
                color: #FFFFFF;
                font-family: "Prompt", Sans-serif;
                font-size: 40px;
                font-weight: 400;
                line-height: 60px;
                padding: 0 0 2rem;
            
            }
            p{
                color: #FFFFFF;
                font-family: "Prompt", Sans-serif;
             
                font-weight: 400;
                line-height: 30px;
              
            }
        }
    }
}
}

.heads{
   
    h1{
        font-size: 3rem;
        font-family: "popins", Sans-serif;
        color: #eee;
        text-align: center;
        padding: 5rem 0 1rem;
    }
  h3{
    font-size: 3rem;
    font-family: "Montserrat", Sans-serif;
    color: #eee;
    text-align: center;
    padding: 2rem 0 1rem;
    font-weight: 600;
  }

}
.delay{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .articles{
        // display: grid;
        // grid-template-columns: repeat(,1fr);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3rem;
        align-items: center;
        flex-basis: calc(70% - 10px); 
        margin: 5px; 
        padding: 3rem 7rem;
    
        .container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .image{
                width: 200px;
                height: 200px;
                // position: relative;
                transition: all ease .3s;
                position: relative;
                
            }
            .text{
                text-align: center;
                padding: 1rem;
    
                h2{
                    font-size: 1.9rem;
                    color: #eee;
                }
                p{
                    font-size: 1.5rem;
                    padding: 1rem 0;
                    color: #fbbb25;
                    font-family: "Montserrat", Sans-serif;
                    font-weight: 900;
                }
            }
            .name{
                position: absolute;
                top: 0;
                width: 100%;
                border-radius: 50%;
                height: 100%;
                background-color: #0000008c;
                // width: fit-content;
                display: none;
                justify-content: center;
                align-items: center;
                transition: .5s;
                font-size: 1.5rem;
                color: #ffff;
                // transition-duration:background .4s;
            }
            &:hover .name{
            display: flex;
            }
    
        }
       
            
         
    
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                border: 2px solid #fbbb25;
            }
            
    
    
    
    
    }
}




}