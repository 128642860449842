@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.card-container{
    padding: 3rem;
    .card{
        background-color: rgb(255, 255, 255);
        border: 1px solid rgba(0, 0, 0, 0.062);
        padding: 4rem 3rem;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.281);
       
        
        .profile{
            display: flex;
            align-items: flex-end;
            gap: 2rem;
            .prof{
                width: 50px;
                height: 50px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            h3{
               
                font-size: 1.9rem;
                text-transform: capitalize;
                font-family: "Nunito", sans-serif;
            }
            p{
                font-size: 1.5rem;  
                color: rgba(0, 0, 0, 0.603);
            }
            .ank{
    
                color: #fdc800;
                font-size: 1.7rem;
                text-decoration: none;
                
            }
    
        }
        .desc{
            font-size: 1.6rem;
            padding-bottom: 2rem;
            text-transform: capitalize;
            color: rgba(0, 0, 0, 0.733);
            
        }
    }

   
}
.cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: flex-end;
    gap: 2rem;
    justify-content: center;
    
   
}
@media screen and (max-width:1036px) {
    .cards{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:604px) {
    .cards{
        grid-template-columns: repeat(1,1fr);
       
    }
}
.protection{
    padding: 2rem 10rem;
   .head{
    text-align: center;
    padding: 3rem 0 3rem;
    font-family: "Nunito", sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 100%;
    p{
        font-size: 3rem;
    }
   }
   .sponsor{
    display: flex;
    flex-direction: column;
   padding: 2rem 3rem;

  

    p{
        margin: 0 auto;
        font-size: medium;
    }
    .our{
        display: flex;
        gap: 3rem;
        text-transform: uppercase;
        padding: 2rem 1rem;
        margin: 0 auto;
        color: #777777;
    }
   }
}

.protection{
   padding: 0;
}
.form{
 margin-top: 10%;
 
}
// ------------------------------videocard-------------------------------
.videoz{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;

}
@media screen and (max-width:1073px) {
    .videoz{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-content: center;
    
    }
}

@media screen and (max-width:673px) {
    .videoz{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        justify-content: center;
    
    }
}

.delay{
 padding: 3rem 2rem;

.videos{
    display: flex;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
}

}
.videos{
    display: flex;
   justify-content: center;
   gap: 4rem;
   flex-wrap: wrap;
  
}

.video-card{
    background-color: rgba(255, 255, 255, 0.507);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.096);
    width: 300px;

        .you{
            width: 100%;
        }
    
    .image{
        width: 300px;
        height: 200px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .video-content{
        font-family: "Nunito", sans-serif;
        padding: 1rem 2rem;
        margin: 0 30px;
        h1{
            color: #fdc800;
            text-transform: capitalize;
            font-size: 2rem;
        }
        p{
            font-size: 1.6rem;
            text-transform: capitalize;
            font-weight: 400;
           
        }
        .youtube{
            font-size: 1.6rem;
            text-transform: capitalize;
            font-weight: 700;
            color: #00043b;
            text-decoration: none;
        }
        .btn{
            font-size: 1.6rem;
            text-transform: capitalize;
            font-weight: 700;
            color: #ffffff;
            background-color: #00043b;
            transition: all .6s;
            &:hover{
                background: transparent;
                border: 1.2px solid #00043b;
                color: #00043b;
            }
        }
    }
}

@media screen and (max-width:315px) {
    .video-card{
        width: 200px;
        .image{
            width: 100%;

            img{
                width: 100%;
            }
        }
    
    }
}
.did{
    background-color: rgb(255, 255, 255);
    padding: 3rem 2rem;
    display: flex;
   margin: 25px 0;
   justify-content: center;
   .description-container{
  
    // display: flex;
    // justify-content: center;
    margin: 100px 0;
    p{
      padding: 0 5rem 0 2rem;
        font-size: 1.8rem;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-family: "Nunito", sans-serif;
    }
   }
    .image{
        width: 500px;
        height: 500px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
@media screen and (max-width:953px) {
    .did{
        flex-wrap: wrap;
    }
}
.icon-footer{
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.274);
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    
    .icon{
        font-size: 4rem;
    }
}