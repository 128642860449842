.home-bottom{
        width: 100%;
        height: 200px;
        background: #002147;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        padding: 3rem;
        @media(max-width:876px){
            gap: 2rem;
        }
        @media(max-width:600px){
            gap: 1rem;
        }
        @media(max-width:456px){
            height: auto;
            display: flex;
            flex-direction: column;
        }
        .home-bottom-img{
            height: 100%;
            width: 200px;
            @media(max-width:456px){
                height: 100%;
                width: 100%;     
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .home-bottom-txt{
            display: flex;
            flex-direction: column;
            @media(max-width:456px){
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                text-align: center;
               }
            h2{
                font-size: 2rem;
                font-weight: 900;
                color:#fff;
                @media(max-width:700px){
                    font-size: 1.7rem;
                }
                @media(max-width:500px){
                    font-size: 1.5rem;
                }
                span{
                    color: #fdc800;
                }
            }
            p{
                font-size: 1.6rem;
                font-weight: 200;
                color: #fff;
                @media(max-width:700px){
                    font-size: 1.6rem;
                }
                @media(max-width:500px){
                    font-size: 1.4rem;
                }
            }
        }
        .home-bottom-date{
            display: flex;
            justify-content: space-between;
            gap: 2rem;
            @media(max-width:700px){
                gap: 1rem;
            }
            @media(max-width:700px){
                gap: 0.4rem;
            }
            @media(max-width:500px){
                gap: 0.2rem;
            }
            @media(max-width:456px){
             height: 100%;
             width: 100%;     
            }
            @media(max-width:244px){
                display: flex;
                flex-direction: column;    
                gap: 2rem;
               }
            .colon{
                font-size: 2rem;
                color: #fff;
                padding-top: 1rem;
                @media(max-width:700px){
                    font-size: 1.5rem;
                }
                @media(max-width:600px){
                    font-size: 1.4rem;
                    padding-top: .6rem;
                }
                @media(max-width:600px){
                    font-size: 1.2rem;
                }
                @media(max-width:244px){
                    display: none;   
                   }
            }
            p{
                color: #fff;  
            }
            .days{
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                h1{
                    color: #fff;
                    font-size: 4rem;
                    @media(max-width:700px){
                        font-size: 3rem;
                    }
                    @media(max-width:600px){
                        font-size: 2rem;
                    }
                    @media(max-width:500px){
                        font-size: 1.6rem;
                    }
                }
                p{
                    font-size: 1.4rem;
                    font-weight: 100;
                }
            }
            .hours{
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                h1{
                    color: #fff;
                    font-size: 4rem;
                    @media(max-width:700px){
                        font-size: 3rem;
                    }
                    @media(max-width:600px){
                        font-size: 2rem;
                    }
                    @media(max-width:500px){
                        font-size: 1.6rem;
                    }
                }
                p{
                    font-size: 1.4rem;
                    font-weight: 100;
                }
            }
            .minutes{
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                h1{
                    color: #fff;
                    font-size: 4rem;
                    @media(max-width:700px){
                        font-size: 3rem;
                    }
                    @media(max-width:600px){
                        font-size: 2rem;
                    }
                    @media(max-width:500px){
                        font-size: 1.6rem;
                    }
                }
                p{
                    font-size: 1.4rem;
                    font-weight: 100;
                }
            }
            .seconds{
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                h1{
                    color: #fff;
                    font-size: 4rem;
                    @media(max-width:700px){
                        font-size: 3rem;
                    }
                    @media(max-width:600px){
                        font-size: 2rem;
                    }
                    @media(max-width:500px){
                        font-size: 1.6rem;
                    }
                }
                p{
                    font-size: 1.4rem;
                    font-weight: 100;
                }
            }
        }
    }