*,*::after,*::before{
    padding: 0;
    margin: 0;
}
html, body {
    overflow-x: hidden;
  }
  body {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
  }
