.award-container{
    width: 100%;
    height: auto;
    padding: 3rem;
    .award-div{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        @media(max-width:886px){
            display: flex;
            flex-wrap: wrap;
        }
    }
    .award-one{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        @media(max-width:420px){
           width: 100%;
           height: auto;
        }
        .count{
            color: #112f4f;
            font-size: 4rem;
            font-weight: 800;
        }
        p{
            color: #00b2b2;
            font-size: 1rem;
            font-weight: 800;
        }
    }
}